<template>
  <v-container container--fluid>
    <!-- <v-row class="mt-12 mb-4" justify="center">
      <v-col cols="10" md="10">
        <h2 class="mt-4 headline">환영합니다.</h2>
      </v-col>
    </v-row> -->
    <v-row justify="center">
      <!-- <v-col cols="10" md="5">
        <p v-if=isMatching()>
          <span class="font-weight-bold">슈퍼차트의 마케팅 캠페인을 지금 이용해보세요.</span><br/><br/>
          <span class="label">슈퍼차트에서 인플루언서의 순위와 영향력을 직접 확인하고, <br>원하는 인플루언서에게 광고 캠페인을 제안해보세요.</span>
        </p>
        <p v-else>
          <span class="font-weight-bold">슈퍼멤버스에 가입하고 최상위 블로거들의 영향력을<br/>직접 경험해보세요.<br/><br/></span>
          <span class="label">가입 및 광고 등록과정은 약 5분이 소요됩니다.</span>
        </p>
      </v-col> -->
      <!-- <v-col cols="10 my-4" class="hidden-md-and-up">
        <div class="line"></div>
      </v-col> -->
      <v-col cols="10" md="5">
        <v-col cols="10" md="10">
          <h2
            class="mt-4 headline"
            style="font-weight: 700; font-size: 24px; line-height: 30px"
          >
            1분만에 관리자 계정을 만들고,<br />빠르게 블로거들을 만나보세요.
          </h2>
        </v-col>
        <v-col>
          <v-btn
            color="rgba(58, 58, 58, 1)"
            class="blog-button"
            @click="moveBlogSite"
            >네이버 블로거신가요?</v-btn
          >
        </v-col>
        <p>
          <span class="font-weight-bold">필수 정보를 입력해주세요.</span><br />
        </p>
        <v-row class="mb-8">
          <v-col cols="12" class="mt-5 mb-8">
            <form @submit.prevent="onSubmit" id="signUpForm">
              <v-col>
                <v-col v-if="!userIsAuthenticated">
                  <v-col>
                    <v-col>
                      <div class="label">이메일 주소*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.email"
                        name="email"
                        type="email"
                        v-validate="'required|email'"
                        :rules="[errors.first('email') || !errors.has('email')]"
                        single-line
                        outlined
                        solo
                        flat
                        required
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col v-if="!userIsAuthenticated">
                  <v-col>
                    <v-col>
                      <div class="label">비밀번호*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.password1"
                        name="password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="
                          showPassword ? 'visibility' : 'visibility_off'
                        "
                        ref="password"
                        placeholder="영어대소문자, 숫자로 8자 이상"
                        v-validate="'required|min:8'"
                        :rules="[
                          errors.first('password') || !errors.has('password'),
                        ]"
                        @click:append="showPassword = !showPassword"
                        single-line
                        outlined
                        solo
                        flat
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <!--            <v-col v-if="!userIsAuthenticated">-->
                <!--              <v-col>-->
                <!--                <v-col>-->
                <!--                  <div class="label">비밀번호 확인</div>-->
                <!--                </v-col>-->
                <!--                <v-col mt-1>-->
                <!--                  <v-text-field v-model="form.password2" name="password_confirmed" type="password"-->
                <!--                                v-validate="'required|min:8|confirmed:password'"-->
                <!--                                :rules="[errors.first('password_confirmed') || !errors.has('password_confirmed')]"-->
                <!--                                single-line outlined solo flat required></v-text-field>-->
                <!--                </v-col>-->
                <!--              </v-col>-->
                <!--            </v-col>-->
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">업체명*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.name"
                        name="name"
                        type="text"
                        v-validate="'required'"
                        :rules="[errors.first('name') || !errors.has('name')]"
                        single-line
                        outlined
                        solo
                        flat
                        required
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">성함*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.charger"
                        name="charger"
                        type="text"
                        v-validate="'required'"
                        :rules="[
                          errors.first('charger') || !errors.has('charger'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                        required
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">휴대전화 번호*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.contacts"
                        name="contacts"
                        type="text"
                        v-validate="'required|min:10|phoneNumber'"
                        :rules="[
                          errors.first('contacts') || !errors.has('contacts'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                        required
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col class="mb-4">
                  <span class="font-weight-bold"
                    >선택 정보를 입력해주세요.</span
                  >
                </v-col>
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">가입 경로</div>
                      <v-select
                        v-model="form.route"
                        :items="
                          Object.entries(option.root).map(([k, v]) => ({
                            value: k,
                            text: v,
                          })) || []
                        "
                        item-text="text"
                        item-value="value"
                        outlined
                        solo
                        flat
                      ></v-select>
                    </v-col>
                    <v-col class="mt-1"> </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">추천인</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.recommended"
                        name="recommended"
                        type="text"
                        :rules="[
                          errors.first('recommended') ||
                            !errors.has('recommended'),
                        ]"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <label>
                    <input type="checkbox" required />
                    {{ isMatching() ? '슈퍼차트' : '슈퍼멤버스' }}
                    <router-link
                      target="_blank"
                      to="/terms/"
                      class="red--text text--lighten-1"
                      >이용약관</router-link
                    >
                    및
                    <router-link
                      target="_blank"
                      to="/terms/"
                      class="red--text text--lighten-1"
                      >개인정보취급방침</router-link
                    >
                    에 동의합니다.
                  </label>
                  <br />
                  <label>
                    <input v-model="form.agree_to_mail" type="checkbox" />
                    주요 공지사항 및 마케팅 결과 보고 메일 수신에 동의합니다.
                    (선택)
                  </label>
                </v-col>
                <v-col class="mt-4 mb-12 text-center">
                  <v-btn depressed color="primary" type="submit"
                    >가입하기</v-btn
                  >
                </v-col>
              </v-col>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Signup',
  mixins: [userMixin],
  data() {
    return {
      form: {
        password1: '',
        password2: '',
        name: '',
        charger: '',
        contacts: '',
        email: '',
        route: 'None',
        recommended: '',
        agree_to_mail: '',
      },
      option: { root: {} },
      showPassword: false,
    };
  },
  methods: {
    moveBlogSite() {
      location.href = 'https://rank.supermembers.co.kr/';
    },
    onSubmit() {
      this.$validator
        .validateAll()
        .then((success) => {
          if (!success) {
            return;
          }
          if (this.userIsAuthenticated) {
            this.$store.dispatch('logout');
            return null;
            // return this.$store.dispatch('signCompanyUp', {...this.form, email: this.user.email})
          } else {
            return this.$store.dispatch('logout').then(() => {
              return this.$store.dispatch('signUserUp', this.form).then(() => {
                // return this.$store.dispatch('signCompanyUp', {...this.form, step: 0})
              });
            });
          }
        })
        .then(() => {
          if (this.errors.any()) {
            this.$store.commit('setMsg', this.errors.all()[0]);
            this.$store.commit('setShowSnackbar', true);
          } else if (this.$store.getters.error) {
            this.$store.commit(
              'setMsg',
              this.$store.getters.error.message || this.$store.getters.error
            );
            this.$store.commit('setShowSnackbar', true);
          } else {
            if (this.isMatching()) {
              this.axios
                .put('/company/user', {
                  id: this.user.id,
                  last_login_matching: new Date(),
                })
                .then(() => {
                  this.$router.push('/campaign/search');
                });
            } else {
              this.axios
                .put('/company/user', {
                  id: this.user.id,
                  last_login: new Date(),
                })
                .then(() => {
                  this.$router.push('/company/report');
                });
            }
          }
        });
    },
    isMatching() {
      return (
        process.env.VUE_APP_TARGET === 'campaign' ||
        location.href.indexOf('superchart') > -1
      );
    },
  },
  created() {
    if (this.userIsAuthenticated) {
      if (this.isMatching()) {
        this.$router.push('/campaign/search');
      } else {
        this.$router.push('/company/report');
      }
    } else {
      this.axios
        .get('/company/option')
        .then((option) => {
          delete option.data.root['A'];
          delete option.data.root['B'];
          this.option = option.data;
          if (this.isMatching()) {
            this.option.root['None'] = '슈퍼차트를 어떻게 아시게 되셨습니까?';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/form.scss';
@import '../../assets/scss/custom';

.line {
  border-bottom: 1px solid #484848;
}

::v-deep .v-btn {
  margin-left: 0;
  margin-right: 0;
}

.label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #484848;
}

.blog-button {
  color: white;
  margin: 40px 0px;
}

@media (max-width: 724px) {
  .blog-button {
    margin: 24px 0px;
  }
}
</style>
